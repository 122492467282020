.pin-field {
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.0rem;
  font-size: 1.5rem;
  font-family: 'Avenir-Heavy';
  margin: 0.0rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 2.5rem;
}
